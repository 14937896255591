<template>
    <v-card rounded="xl" color="primary" elevation="1" class="text-body-2 pa-5">
        <v-card-title><h3 class="mx-auto">Paid submission</h3></v-card-title>
        <v-card-subtitle class="text-center my-5"
            >Get maximum exposure, featured top position on the listing front
            page. Our team works everyday to optimise seo ranking, buying
            targeted keywords on search engines, we have the best traffic in the
            NFT space. Boost your sales now with one of our sponsored offers:
        </v-card-subtitle>
        <v-card-text>
            <v-row class="px-10">
                <div class="d-flex mr-5 my-2">
                    <img
                        width="60"
                        height="60"
                        :src="require('@/assets/icons/feature.svg')"
                        alt="feature icon"
                    />
                </div>
                <v-col>
                    <h4 class="font-weight-bold dark--text">
                        Featured top position
                    </h4>
                    <p>
                        This is the best emplacement, you will get most of our
                        users attention
                    </p>
                </v-col>
            </v-row>
            <v-row class="px-10">
                <div class="d-flex mr-5 my-2">
                    <img
                        width="60"
                        height="60"
                        :src="require('@/assets/icons/twitter.svg')"
                        alt="twitter icon"
                    />
                </div>
                <v-col>
                    <h4 class="font-weight-bold dark--text">Twitter post</h4>
                    <p>
                        Get exposure on twitter, we will post to our +10k
                        followers
                    </p>
                </v-col>
            </v-row>
            <v-row class="px-10">
                <div class="d-flex mr-5 my-2">
                    <img
                        width="60"
                        height="60"
                        :src="require('@/assets/icons/gift.svg')"
                        alt="gift icon"
                    />
                </div>

                <v-col>
                    <h4 class="font-weight-bold dark--text">
                        Special viral giveaway
                    </h4>
                    <p>
                        Increase brand awareness, followers and virality on
                        social media.
                    </p>
                </v-col>
            </v-row>
            <v-row class="px-10">
                <div class="d-flex mr-5 my-2">
                    <img
                        width="60"
                        height="60"
                        :src="require('@/assets/icons/star.svg')"
                        alt="star icon"
                    />
                </div>
                <v-col>
                    <h4 class="font-weight-bold dark--text">
                        Full exposure pack
                    </h4>
                    <p>Get the best we can offer, at a discounted price!</p>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <GradientButton to="contact-us" class="mx-auto">
                Contact us for more informations
            </GradientButton>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: "PaidSubmission",
};
</script>

<style lang="scss" scoped>
</style>
